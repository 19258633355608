<template>
  <div id="otp">
    <v-flex xs12>
      <h4> {{ $t('otpTitle') }}</h4>
      <v-flex xs12 sm12 md12>
        <v-form
          ref="form"
          class="mt-3 mb-0"
          v-model="valid"
          lazy-validation
        >
          <v-layout wrap>
            <v-flex xs4 offset-xs4>
              <v-text-field
                v-model="otp"
                ref="otp"
                :maxlength="4"
                @input="handleOnComplete($event)"
                outlined
                required
              ></v-text-field>
            </v-flex>  
          </v-layout>
        </v-form>
        <p v-if="numbers > 0" class="text mb-2 mt-0"> {{seconds}} {{ $t('countdown.seconds') }} </p>
        <p class="text2 mb-2"> {{ $t('otpMessage') }} </p> 
        <p class="resend" @click="resendOtp()"> {{ $t('resendOtp') }}</p>

        <v-btn tile :color="bgColor" dark class="mt-5" @click="close()">
           {{ $t('tutup') }} 
        </v-btn>
      </v-flex>
    </v-flex>
  </div>
</template>

<script>
  import { mapState } from 'vuex'
  import axios from '@/axios'

  export default {
    name: 'Otp',
    props: {
      user_id: {
        type: String,
        default: ''
      },
    },
    computed: {
      ...mapState([
        'isMobile',
        'userEmail',
        'token',
        'bgColor'
      ]),
      seconds() {
        if (this.numbers > 0 ) {
          return this.digits(this.numbers)
        } else {
          return '00'
        }
      }
    },
    data () {
      return {
        valid: true,
        otp: '',
        numbers: 60
      }
    },
    methods: {
      close () {
        this.$emit('close')
      },
      handleOnComplete (value) {
        if (value.length === 4) {
          this.otpVerification()
        }
      },
      otpVerification () {
        this.$store.commit('setIsLoading', true)

        let data = {
          id_pelanggan: this.user_id,
          otp: this.otp,
        }
      
        axios.post('/api/v1/member/verify_otp', data)
          .then(response => {
            this.$store.commit('setSnackbar', true)
            this.$store.commit('setText', response.data.message)
            this.$emit('verified')
          })
          .catch(error => {
            this.errorHandling(error)
          })
          .finally(() => {
            this.$store.commit('setIsLoading', false)
          })
      },
      resendOtp () {
        this.$store.commit('setIsLoading', true)

        let data = {
          id_pelanggan: this.user_id
        }
      
        axios.post('/api/v1/member/resend_otp', data)
          .then(response => {
            this.$store.commit('setSnackbar', true)
            this.$store.commit('setText', response.data.message)
            this.numbers = 60
          })
          .catch(error => {
            this.errorHandling(error)
          })
          .finally(() => {
            this.$store.commit('setIsLoading', false)
          })
      },
      digits (value) {
        if (value.toString().length <= 1) {
          return "0"+value.toString()
        }
        return value.toString();
      },
      ready () {
        setInterval(() => {
          this.numbers--
        }, 1000)
      }
    },
    mounted () {
      this.ready()
    }
  }
</script>

<style scoped>
  #otp {
    padding: 20px;
    text-align: center;
    background-color: #fff;
  }

  .v-input {
    font-size: 28px;
  }

  .text {
    color: red;
    font-size: 10px;
  }

  .text {
    font-size: 14px;
  }

  .resend {
    text-decoration: underline;
    cursor: pointer;
  }

</style>
