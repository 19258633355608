<template>
  <div id="login-form">
    <v-flex xs12>
      <v-layout wrap class="login">
        <v-flex xs12>
          <div class="box-2">
            <span class="text-2"> You're not logged in yet  </span>
            <v-form
              ref="form"
              class="mt-9"
              v-model="valid"
              lazy-validation
            >
              <v-text-field
                outlined
                v-model="email"
                ref="email"
                label="Email"
                :rules="emailRules"
                required
              ></v-text-field>

              <v-text-field
                outlined
                type="password"
                v-model="password"
                ref="password"
                :label="$t('login-form.password')"
                :rules="passRules"
                required
              ></v-text-field>

              <v-btn class="mt-5" tile :color="bgColor" dark width="100%" height="44px" @click="login()">
                <span class="btn-text">  Login  </span>
              </v-btn>
            </v-form>

            <p class="p-text">
              <span class="link-f" @click="toPage('Forget-Password')">{{ $t('login-form.forget')}} </span>
            </p>

            <p class="p-text">
              {{ $t('login-form.no-account')}} <span class="link" @click="toPage('Register')"> {{ $t('login-form.register')}}  </span>
            </p>
          </div>
        </v-flex>
      </v-layout>
    </v-flex>

    <v-dialog v-model="dialog"
      width="300px"
      transition="dialog-bottom-transition"
      scrollable
      :persistent="true"
    >
      <Otp :user_id="user_id" @verified="success()" @close="dialog = false" />
    </v-dialog>
  </div>
</template>

<script>
  import { mapState } from 'vuex'
  import axios from '@/axios'
  import crypto from '@/crypto'
  import Otp from '@/components/Otp'

  export default {
    name: 'Login',
    components: { Otp },
    computed: {
      ...mapState([
        'isMobile',
        'isLogin',
        'cartNumber',
        'bgColor'
      ])
    },
    data () {
      return {
        dialog: false,
        user_id: '',
        applicationName: { name: this.appName},
        email: '',
        password: '',
        valid: true,
        emailRules: [
          v => !!v || this.$t('login-form.form.error-email'),
          v => /^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/.test(v.trim()) || this.$t('login-form.form.error-valid-email')
        ],
        passRules: [
          v => !!v || this.$t('login-form.form.error-password'),
        ],
      }
    },
    methods: {
      needOtp () {
        this.dialog = true
      },
      toPage (item) {
        this.$store.commit('setLoginDialog', false)
        this.$router.push({ name: item })
      },
      success () {
        this.dialog = false
        this.$emit('close')
      },
      login () {
        if (this.$refs.form.validate()) {
          this.$store.commit('setIsLoading', true)

          let auth = {
            username: this.email.trim(),
            password: this.password,
          }

          axios
            .post('/api/v1/member/login', {}, {
              auth: auth
            })
            .then(response => {
              localStorage.setItem('prepp_n', crypto.encrypt(response.data.data.nama))
              localStorage.setItem('prepp_t', crypto.encrypt(response.data.data.token))
              localStorage.setItem('prepp_e', crypto.encrypt(this.email))

              this.$store.commit('setSnackbar', true)
              this.$store.commit('setText', response.data.message)
              this.$store.commit('setIsLogin', true)
              this.$store.commit('setIsLogin', true)
              this.$store.commit('setUserLogin', { name: response.data.data.nama, email: this.email, token: response.data.data.token})

              this.$emit('close')
              window.location.reload();
            })
            .catch(error => {
              if (error.response.data.data && error.response.data.code === 'VER001') {
                this.user_id = error.response.data.data.id_pelanggan
                this.needOtp()
              }
              this.errorHandling(error)
            })
            .finally(() => {
              this.$store.commit('setIsLoading', false)
            })
        } 
      }
    },
    mounted () {}
  }
</script>

<style scoped>
  #login-form {
    background-color: #fff;
  }

  .text {
    font-size: 24px;;
    color: #000000;
    font-weight: 600;
  }

  .text-2 {
    font-size: 18px;;
    color: #000000;
    font-weight: 600;
  }

  .p-text {
    margin-top: 15px;
    font-size: 14px;;
    color: #000000;
  }

  .btn-text {
    font-weight: 500;
    color: #FFFFFF;
    text-decoration: none;
  }

  .login {
    color: #FFFFFF;
    position: relative;
    margin: auto;
  }
  
  .box {
    padding: 30px;
  }

  .box-2 {
    text-align: center;
    padding: 15px;
  }

  .link {
    text-decoration: underline;
    color: #000000;
    cursor: pointer;
    font-weight: bold;
  }

  .link-f {
    text-decoration: none !important;
    color: #000000;
    cursor: pointer;
  }

  .uppercase {
    text-transform: uppercase;
  }

</style>
