<template>
  <v-snackbar
    v-model="snackbar"
    :right="!isMobile"
    dark
    timeout="-1"
    :top="true"
  >
    <span v-html="text"></span>

    <template v-slot:action="{ attrs }">
      <v-btn
        dark
        text
        v-bind="attrs"
        @click="close()"
      >

        <v-icon medium>mdi-close</v-icon>
      </v-btn>
    </template>
  </v-snackbar>
</template>

<script>
  import { mapState } from 'vuex'

  export default {
    name: 'Snackbar',
    computed: {
      ...mapState([
        'snackbar',
        'text',
        'isMobile'
      ])
    },
    methods: {
      close () {
        this.$store.commit('setSnackbar', false)
        this.$store.commit('setText', '')
      }
    }
  }
</script>

<style scoped>
</style>
