<template>
  <v-app id="content-wrap" :class="isMobile ? 'myApp' : 'myApp-dekstop'" :style="route === 'Home' ? { 'background-color': '#E5E5E5' } : {'background-color': '#fff' }">
    <Loader v-if="isLoading"/>
    <Header
      v-if="!showWelcome"
      @showHide="setPanel(true)"
    >
    </Header>
    <Sidebar
      v-if="!showWelcome"
      :isPanelOpen="isSideBarOpen"
      @showHide="setPanel(false)">
      <v-list :color="bgColor">
        <template v-if="appBrand === '14'">
          <v-list-group v-for="(item, index) in menuItems" :key="index"
            color="#fff"
            :value="item.active"
            :append-icon="item.route ? '' : 'mdi-chevron-down'"
            active-class="active"
            @click="actions(item.title)"
          >
            <template v-slot:activator>
              <v-list-item-content>
                <v-list-item-title>
                  <span :style="{'color': item.color}"> {{ item.title }}</span>
                </v-list-item-title>
              </v-list-item-content>
            </template>
            <template v-if="!item.route">
              <v-list-group  v-for="(sub, i) in item.items" :key="i"
                :value="sub.active"
                append-icon=""
                active-class="active"
              >
                <template v-slot:activator>
                  <v-list-item-content @click="actionsSub(sub.title)" class="pl-3">
                    <v-list-item-title>
                      <span :style="{'color': sub.color}"> {{ sub.title }}</span>
                    </v-list-item-title>
                  </v-list-item-content>
                </template>
              </v-list-group>
            </template>
          </v-list-group>
        </template>

        <v-list-group
          color="#fff"
          append-icon=""
          active-class="active"
          @click="toSpecificPage('FLUFFLY TOTEBAG')"
          v-if="appBrand === '5'"
        >
          <template v-slot:activator>
            <v-list-item-content>
              <v-list-item-title>
                <span class="uppercase" :style="{'color': '#fff'}">FLUFFLY TOTEBAG</span>
              </v-list-item-title>
            </v-list-item-content>
          </template>
        </v-list-group>

        <v-list-group
          color="#fff"
          append-icon=""
          active-class="active"
          @click="toPage('Order')"
        >
          <template v-slot:activator>
            <v-list-item-content>
              <v-list-item-title>
                <span class="uppercase" :style="{'color': '#fff'}">{{ $t('account.purchase.title') }} </span>
              </v-list-item-title>
            </v-list-item-content>
          </template>
        </v-list-group>
      </v-list>

      <ul class="sidebar-panel-nav mt-16 pl-2">
        <li class="sub-c" @click="showBottomMenu()">+ INFO</li>
        <div v-if="bottomMenu">
          <!-- <li class="sub-c" v-if="idBrand === '15'" @click="toPage('Confirm-Payment')">{{ $t('menu.confirm-payment') }}</li> -->
          <!-- <li class="sub-c" @click="toPage('Order')">{{ $t('account.purchase.title') }}</li> -->
          <!-- <li class="sub-c" @click="toPage('Article')">{{ $t('menu.article') }}</li> -->
          <li class="sub-c" @click="toPage('Help')">{{ $t('menu.help') }}</li>
          <li class="sub-c" @click="toPage('Faq')">{{ $t('menu.faq') }}</li>
          <li class="sub-c" @click="toPage('Company')">{{ $t('menu.company') }}</li>
        </div>
      </ul>
    </Sidebar>
    <Snackbar />
    <router-view v-if="!showWelcome" class="mb-16" :key="$route.fullPath" />
    <v-footer
      v-if="!showWelcome"
      id="footer"
      :style="footer && footer.color.font ? {'color': footer.color.font} : {'color': 'black'}"
      :color="footer && footer.color.background ? footer.color.background : 'white'"
    >
      <v-col
        cols="12"
        v-if="footer"
        :style="footer && footer.color.background  ? {'background-color': footer.color.background} : {'background-color': 'white'}"
      >
       <v-layout wrap class="pl-4 pr-4">
          <v-flex xs12 sm4 md5 class="mt-5">
            <span class="footer-title-b-local"> {{footer.website_name}} </span>
            <div class="mt-4">
              <span class="custom-p" v-html="footer.deskripsi"></span>
            </div>
            <v-divider v-if="isMobile" class="mt-4" :color="footer && footer.color.font ? footer.color.font : 'white'"></v-divider>
          </v-flex>
          <v-flex xs12 sm4 md4 class= "mt-5">
            <span class="footer-title-b-local"> FOLLOW US </span>
            <v-layout class="mt-4">
              <div v-for="(image, index) in footer.social_media" :key="index">
                <img v-bind:src="image.logo" width="25px" alt="item" @click="toLink(image.link)" class="mr-4">
              </div>
            </v-layout>
            <v-divider v-if="isMobile" class="mt-4" :color="footer && footer.color.font ? footer.color.font : 'white'"></v-divider>
          </v-flex>
          <v-flex xs12 sm4 md3 class= "mt-5">
            <div>
              <span class="footer-title-local"> Metode Pembayaran </span>
              <v-layout wrap class="mt-4 payment-method">
                <v-flex xs12 class="text-center">
                  <img  v-for="(image, index) in footer.metode_pembayaran" :key="index" v-bind:src="image.logo" width="35px" alt="item" class="mr-2">
                </v-flex>
              </v-layout>
              <v-divider v-if="isMobile" class="mt-4" :color="footer && footer.color.font ? footer.color.font : 'white'"></v-divider>
            </div>
            <!-- <div class="mt-5">
              <span class="footer-title-local"> Metode Pengiriman </span>
              <v-layout class="mt-4">
                <div>
                  <img v-bind:src="footer.metode_pengiriman.logo" width="32px" alt="item">
                </div>
              </v-layout>
            </div> -->
          </v-flex>
        </v-layout>
      </v-col>

      <v-col
        class="text-center pl-4 pr-4 pt-0"
        cols="12"
        :style="footer && footer.color.background  ? {'background-color': footer.color.background} : {'background-color': 'white'}"
      >
        <v-layout wrap>
          <v-flex xs12 class="footer-text-local mt-4">
            Copyright &copy; {{appName}}.  All Right Reserved
          </v-flex>
        </v-layout>
      </v-col>

    </v-footer>

    <Welcome v-if="showWelcome" @close="closeWelcome()"/>


    <div v-if="route !== 'Login' && route !== 'Register' && route !== 'Forget-Password'" :class="route === 'Home' ? 'float-button-home' : 'float-button'" :style="{ 'background-color' : bgColor}" @click="openScan">
      <img class="float-button-img" type="image/webp" src="@/assets/icon-scan.webp" alt="scan">
    </div>

    <v-dialog v-model="dialog"
      width="300px"
      transition="dialog-bottom-transition"
      scrollable
    >
      <LoginForm v-if="dialog" @close="close()" />
    </v-dialog>

    <v-dialog v-model="scanDialog"
      :fullscreen="true"
      transition="dialog-bottom-transition"
      scrollable
    >
      <Scan v-if="scanDialog" @close="close()" />
    </v-dialog>
  </v-app>
</template>

<script>
  import Header from './components/Header'
  import Sidebar from './components/Sidebar'
  import Loader from '@/components/Loader'
  import Snackbar from '@/components/Snackbar'
  import LoginForm from '@/components/Login'
  import Scan from '@/components/Scan'
  import { mapState } from 'vuex'
  import axios from '@/axios'
  import Welcome from '@/components/Welcome'

  export default {
    name: 'App',
    components: {
      Header,
      Sidebar,
      Loader,
      Snackbar,
      LoginForm,
      Scan,
      Welcome
    },
    computed: {
      ...mapState([
        'isMobile',
        'isLoading',
        'isSideBarOpen',
        'menuItems',
        'bgColor',
        'isLogin'
      ]),
      route () {
        return this.$route.name ? this.$route.name : 'Home'
      },
      dialog: {
        get: function () { return this.$store.getters.getLoginDialog },
        set:  function (newValue) { this.$store.commit('setLoginDialog', newValue) }
      },
      scanDialog: {
        get: function () { return this.$store.getters.getScanDialog },
        set:  function (newValue) { this.$store.commit('setScanDialog', newValue) }
      }
    },
    watch: {
      route () {
        this.scanDialog = false
      }
    },
    data () {
      return {
        bottomMenu: false,
        appName: process.env.VUE_APP_NAME,
        appBrand:  process.env.VUE_APP_BRAND,
        footer: null,
        showWelcome: false,
        isDekstopPage: false
      }
    },
    methods: {
      toSpecificPage (name) {
        this.$router.push({ name: 'Shop', params: { category: 'category', id: name }})
        this.setPanel(false)
      },
      close () {
        this.$store.commit('setLoginDialog', false)
        this.$store.commit('setScanDialog', false)
      },
      closeWelcome () {
        localStorage.setItem('prepp_w', true)
        this.showWelcome = false
        if (this.$route.name !== 'Home') {
          this.$router.push({ name : 'Home'})
        }
      },
      checkWelcomeMessage () {
        let welcome = localStorage.getItem('prepp_w')
        if (!welcome) {
          this.showWelcome = true
        }
      },
      toLink (link) {
        window.open(link, '_blank');
      },
      openScan () {
        this.$store.commit('setScanDialog', true)
      },
      toPage (name) {
        if (this.$route.name !== name) {
          this.$router.push({ name: name });
          this.setPanel(false)
        }
      },
      showBottomMenu () {
        this.bottomMenu = !this.bottomMenu
      },
      onResize () {
        this.$store.commit('setIsMobile', window.innerWidth < 920)
      },
      setMenus (response) {
        let items = []
        response.data.data.sidebar.map(item => {
          if (item.sub) {
            items.push({
              title: item.name,
              route: null,
              color: item.sidebar_color ? item.sidebar_color : '#fff',
              active: false,
              items: item.sub.map(sub => {
                return {
                  title: sub.name,
                  route: sub,
                  color: sub.font_color ? sub.font_color : '#fff',
                  active: false,
                }
              })
            })
          } else {
            items.push({
              title: item.name,
              route: item.name,
              color: item.sidebar_color ? item.sidebar_color : '#fff',
              active: false,
              items: null
            })
          }
        })
        this.$store.commit('setMenuItems', items)
      },
      getMenus () {
        this.$store.commit('setIsLoading', true)
        axios.get('/api/v2/pages/menu')
          .then(response => {
            this.footer = response.data.data.footer
            this.$store.commit('setFooter', this.footer)
            this.setMenus(response)
          })
          .catch(error => {
            this.errorHandling(error)
          })
          .finally(() => {
            this.$store.commit('setIsLoading', false)
          })
      },
      openWhatsapp () {
        let data = this.footer.social_media.find(item => { return item.link.toLowerCase().includes('whatsapp') })
        this.toLink(data.link)
      }
    },
    beforeDestroy () {
      if (typeof window !== 'undefined') {
        window.removeEventListener('resize', this.onResize, { passive: true })
      }
    },
    mounted () {
      this.onResize()
      this.getMenus()
      this.checkWelcomeMessage()

      window.addEventListener('resize', this.onResize, { passive: true })
    }
  }
</script>
<style>

  @font-face {
    font-family: "Futura Light";
    src: url("https://db.onlinewebfonts.com/t/2167e76f00e569cc11b3665679996380.eot");
    src: url("https://db.onlinewebfonts.com/t/2167e76f00e569cc11b3665679996380.eot?#iefix") format("embedded-opentype"),
    url("https://db.onlinewebfonts.com/t/2167e76f00e569cc11b3665679996380.woff2") format("woff2"),
    url("https://db.onlinewebfonts.com/t/2167e76f00e569cc11b3665679996380.woff") format("woff"),
    url("https://db.onlinewebfonts.com/t/2167e76f00e569cc11b3665679996380.ttf") format("truetype"),
    url("https://db.onlinewebfonts.com/t/2167e76f00e569cc11b3665679996380.svg#Futura Light") format("svg")
  }

  @font-face {
    font-family: "Futura PT Medium";
    src: url("https://db.onlinewebfonts.com/t/3c6b0199ae7b57966132e83c939d77e2.eot");
    src: url("https://db.onlinewebfonts.com/t/3c6b0199ae7b57966132e83c939d77e2.eot?#iefix") format("embedded-opentype"),
    url("https://db.onlinewebfonts.com/t/3c6b0199ae7b57966132e83c939d77e2.woff2") format("woff2"),
    url("https://db.onlinewebfonts.com/t/3c6b0199ae7b57966132e83c939d77e2.woff") format("woff"),
    url("https://db.onlinewebfonts.com/t/3c6b0199ae7b57966132e83c939d77e2.ttf") format("truetype"),
    url("https://db.onlinewebfonts.com/t/3c6b0199ae7b57966132e83c939d77e2.svg#Futura PT Medium") format("svg");
  }

  .myApp .v-application--wrap {
    min-height: 60vh !important ;
  }

  .myApp-dekstop .v-application--wrap {
    min-height: 100vh !important ;
  }

  #app {
    font-family: 'Futura Light' !important;
    position: relative;
  }

  .uppercase {
    text-transform: uppercase;
  }

  #content-wrap {
    padding-bottom: 1.0rem;    /* Footer height */
  }

  #footer {
    font-family: Nunito !important;
    position: absolute;
    padding: 0;
    bottom: 0;
    width: 100%;
    height: 2.5rem;           /* Footer height */
  }

  .footer-title-b {
    font-size: 16px;
    font-weight: bold;
  }

  .footer-title {
    font-size: 16px;
  }

  .footer-text {
    font-size: 14px;
  }

  .footer-title-b-local {
    font-family: "Futura PT Medium" !important;
    letter-spacing: 2px;
    font-size: 16px;
    font-weight: bold;
  }

  .footer-title-local {
    font-family: "Futura Light" !important;
    letter-spacing: 2px;
    font-size: 16px;
  }

  .footer-text-local {
    font-family: "Futura Light" !important;
    letter-spacing: 2px;
    font-size: 14px;
  }

  ul.sidebar-panel-nav {
    list-style-type: none;
    padding: 0 !important;
  }

  ul.sidebar-panel-nav > li {
    color: #fff;
    text-decoration: none;
    font-size: 1.3rem;
    font-weight: 500;
    display: block;
    padding-bottom: 0.5em;
    cursor: pointer;
  }

  .nav-sub-c {
    list-style-type: none;
    padding: 0 !important;
  }

  .sub-c {
    font-size: 1rem !important;
    font-weight: normal !important;
    cursor: pointer;
    margin-bottom: 0.2rem;
    color: #fff;
  }

  .sub-c:hover {
    font-weight: bolder !important;
  }

  .active-sub {
    color: #fff !important;
    font-weight: 900 !important;
     background-color: rgba(139,23,36, 0.5);
  }

  .active {
    color: #fff !important;
    font-weight: 900 !important;
    font-size: 1.5rem !important;
    background-color: rgba(139,23,36, 0.5);
  }

  .v-list-item {
    padding: 0 10px !important;
  }

  .footer {
    position: relative !important;
    bottom: 0px;
  }

  .custom-p {
    font-family: "Futura Light" !important;
    letter-spacing: 2px;
  }

  .img-logo {
    width: 100%;
    max-width: 120px;
    cursor: pointer;
  }

  .payment-method {
    background-color: white;
    padding-left: 10px;
    padding-right: 10px;
    border-radius: 10px;
  }

  .category-img {
    max-width: 100%;
    height: auto;
    box-shadow: 1px 2px #c0c0c0;
    border-radius: 10px;
    /* width: 300px;
    height: 300px;
    object-fit: cover; */
    cursor: pointer;
  }

  .float-button {
    cursor: pointer;
    padding: 13px 10px;
    height: 50px;
    width: 50px;
    border-radius: 50%;
    position: fixed;
    top: 50%;
    right: 5%;
    z-index: 100;
  }

  .float-button-home {
    cursor: pointer;
    padding: 13px 10px;
    height: 50px;
    width: 50px;
    border-radius: 50%;
    position: fixed;
    bottom: 5%;
    right: 5%;
    z-index: 100;
  }

  .float-button-img {
    cursor: pointer;
    width: 100%;
  }

  /* Scroll bar */

  .hide-scroll::-webkit-scrollbar {
    display: none;
  }

  /* ::-webkit-scrollbar {
    width: 5px;
  }

  ::-webkit-scrollbar-track {
    border-radius: 20px;
    background-color: #c0c0c0;
  }

  ::-webkit-scrollbar-thumb {
    background: #fff;
    border-radius: 10px;
  }

  ::-webkit-scrollbar-thumb:hover {
    background: #fff;
  } */

</style>
