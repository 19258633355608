import axios from 'axios'

let defaultURL = process.env.VUE_APP_URL
let brand = process.env.VUE_APP_BRAND

export default axios.create({
  baseURL: defaultURL,
  headers: {
    'id_brand': brand,
    'is_offline': true
  }
})
