import moment from 'moment'
import { mapState } from 'vuex'

export default {
  computed: {
    ...mapState([
      'menuItems',
      'productType',
      'canBack'
    ])
  },
  data () {
    return {
      appName: process.env.VUE_APP_NAME,
      idBrand: process.env.VUE_APP_BRAND
    }
  },
  beforeRouteLeave: function(to, from, next) {
    this.$store.commit('setCanBack', true)
    next();
  },
  methods: {
    errorHandling (error) {
      if (error.response && error.response.status === 401) {
        localStorage.removeItem('prepp_n')
        localStorage.removeItem('prepp_e')
        localStorage.removeItem('prepp_t')
        this.$store.commit('setIsLogin', false)
        this.$store.commit('setUserLogin', {
          name: '', 
          email: '',
          token: ''
        })

        this.$store.commit('setLoginDialog', true)

        // this.$router.push({ 'name' : 'Login'})
      }
      this.$store.commit('setSnackbar', true)
      this.$store.commit('setText', error.response.data.message)
    },
    formatDate (val) {
      return moment(val).format('DD/MM/YYYY') 
    },
    formatDate2 (val) {
      return moment(val).format('YYYY-MM-DD') 
    },
    addComma (val) {
      if (val === undefined || val === null) {
        return ''
      }
      if (val[0] === '0') {
        return '0'
      }
      let commaPattern = /\B(?=(\d{3})+(?!\d))/g
      if (Number(val[0]) === 0) {
        val = String(val).substring(1, val.length)
      }
      return String(val).replace(/\D/g, '').replace(commaPattern, ',')
    },
    formatDateTime (val) {
      return moment(val).format('DD/MM/YYYY hh:mm') 
    },
    logout () {
      this.$store.commit('setIsLogin', false)
      this.$store.commit('setUserLogin', {
        name: '', 
        email: '',
        token: ''
      })
      this.$router.push({ name: 'Home'})

      localStorage.removeItem('prepp_n')
      localStorage.removeItem('prepp_e')
      localStorage.removeItem('prepp_t')
    },
    setInactive () {
      let data = this.menuItems.find(val => val.active)
      if (data) {
        data.active = false
        this.$store.commit('setMenuItems', this.menuItems)
      }
    },
    setInactiveSub () {
      let data = this.menuItems.find(val => val.active)
      if (data && data.items) {
        let data2 = data.items.find(val => val.active)
        if (data2) {
          data2.active = false
          this.$store.commit('setMenuItems', this.menuItems)
        }
      }
    },
    actions (title, fromHome = false) {
      this.setInactiveSub()
      this.setInactive()
      this.isActive = title
      let data = this.menuItems.find(val => val.title === title)
      data.active = true
      this.$store.commit('setMenuItems', this.menuItems)
      this.$store.commit('setProductType', 'category')
      if (!data.items){
        this.toShop(data.title)
      } else {
        if (fromHome) {
          this.setPanel(true)
        }
      }
    },
    actionsSub (title) {
      this.setInactiveSub()
      setTimeout(() => {
        let data = this.menuItems.find(val => val.title === this.isActive)
        let sub = data.items.find(val => val.title === title)
        sub.active = true
        this.$store.commit('setMenuItems', this.menuItems)
        this.$store.commit('setProductType', 'subcategory')
        this.toShop(sub.title)
      }, 200)
    },
    toShop (name) {
      if(this.$route.params.id !== name) {
        this.$store.commit('setCategory', name)
        this.$router.push({ name: 'Shop', params: { category: this.productType, id: name }}) 
      }
      this.setPanel(false)
    },
    toProduct (item) {
      this.$router.push({ name: 'Product', params: { id: item.product_code.toLowerCase(), name: item.product_name.replace(/\s/g, '-').toLowerCase() }});
    },
    setPanel (value) {
      this.$store.commit('setSideBarPanel', value)
    },
    back () {
      if (this.canBack) {
        this.$router.back()
      } else {
        this.$router.push({ name: 'Home'})
      }
    },
    ceilValue (value) {
      return Number(String(value).split('.')[0])
    },
    setMetaInfo (item, title) {
      if (item) {
        document.title = item.product_name +' | '+ process.env.VUE_APP_NAME 
        document.querySelector('meta[name="title"]').setAttribute("content",  item.product_name +' | '+ process.env.VUE_APP_NAME)
        document.querySelector('meta[name="description"]').setAttribute("content", process.env.VUE_APP_META_DESCRIPTION)

        document.querySelector('meta[property="og:type"]').setAttribute("content", 'website')
        document.querySelector('meta[property="og:url"]').setAttribute("content", process.env.VUE_APP_BASE_URL + this.$route.fullPath)
        document.querySelector('meta[property="og:title"]').setAttribute("content",  item.product_name +' | '+ process.env.VUE_APP_NAME)
        document.querySelector('meta[property="og:description"]').setAttribute("content", process.env.VUE_APP_META_DESCRIPTION)
        document.querySelector('meta[property="og:image"]').setAttribute("content", item.url_image[0])

        document.querySelector('meta[property="twitter:card"]').setAttribute("content", 'summary_large_image')
        document.querySelector('meta[property="twitter:url"]').setAttribute("content", process.env.VUE_APP_BASE_URL + this.$route.fullPath)
        document.querySelector('meta[property="twitter:title"]').setAttribute("content", item.product_name +' | '+ process.env.VUE_APP_NAME)
        document.querySelector('meta[property="twitter:description"]').setAttribute("content", process.env.VUE_APP_META_DESCRIPTION)
        document.querySelector('meta[property="twitter:image"]').setAttribute("content", item.url_image[0])
      } else {
        document.title =  this.$route.name === 'Home' ?  process.env.VUE_APP_TITLE_DESCRIPTION : title + ' | ' + process.env.VUE_APP_NAME
        document.querySelector('meta[name="title"]').setAttribute("content", this.$route.name === 'Home' ?  process.env.VUE_APP_TITLE_DESCRIPTION : title + ' | ' + process.env.VUE_APP_NAME)
        document.querySelector('meta[name="description"]').setAttribute("content", process.env.VUE_APP_META_DESCRIPTION)

        document.querySelector('meta[property="og:type"]').setAttribute("content", 'website')
        document.querySelector('meta[property="og:url"]').setAttribute("content", process.env.VUE_APP_BASE_URL + this.$route.fullPath)
        document.querySelector('meta[property="og:title"]').setAttribute("content", this.$route.name === 'Home' ?  process.env.VUE_APP_TITLE_DESCRIPTION : title + ' | ' + process.env.VUE_APP_NAME)
        document.querySelector('meta[property="og:description"]').setAttribute("content", process.env.VUE_APP_META_DESCRIPTION)
        document.querySelector('meta[property="og:image"]').setAttribute("content", '')

        document.querySelector('meta[property="twitter:card"]').setAttribute("content", 'summary_large_image')
        document.querySelector('meta[property="twitter:url"]').setAttribute("content", process.env.VUE_APP_BASE_URL + this.$route.fullPath)
        document.querySelector('meta[property="twitter:title"]').setAttribute("content",this.$route.name === 'Home' ?  process.env.VUE_APP_TITLE_DESCRIPTION : title + ' | ' + process.env.VUE_APP_NAME)
        document.querySelector('meta[property="twitter:description"]').setAttribute("content", process.env.VUE_APP_META_DESCRIPTION)
        document.querySelector('meta[property="twitter:image"]').setAttribute("content", '')
      }
    }
  }
}
