import Vue from 'vue'
import App from './App.vue'
import vuetify from './plugins/vuetify'
import router from './router'
import store from './store'
import Mixins from './mixins'
import VueTouch from 'vue-touch'
import VueClipboard from 'vue-clipboard2'
import i18n from './i18n'
import LazyLoadDirective from "./directives/LazyLoadDirective";


Vue.directive("lazyload", LazyLoadDirective);
 

VueClipboard.config.autoSetContainer = true
Vue.use(VueClipboard)

Vue.config.productionTip = false
Vue.use(VueTouch)

Vue.mixin(Mixins)

new Vue({
  vuetify,
  store,
  router,
  beforeCreate () {
    this.$store.commit('initialiseStore')
  },
  i18n,
  render: h => h(App)
}).$mount('#app')
